import React from "react"

const Media = ({ image, alt, children }) => {
  return (
    <article className="media">
      <figure className="media-left">
        <p className="image is-64x64">
          <img src={image} alt={alt} />
        </p>
      </figure>
      <div className="media-content">
        <div className="content">{children}</div>
      </div>
    </article>
  )
}

export default Media
