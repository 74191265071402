import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jamesabaja/MedGrocer/maersk-pbm/src/components/Layout/Layout.js";
import { graphql, navigate, Link } from "gatsby";
import Container from "../../components/Layout/Container";
import Hero from "../../components/Layout/Hero";
import Button from "../../components/Elements/Button";
import Media from "../../components/Elements/Media";
import Img from "gatsby-image";
import dental from "../../../static/images/coverage_dental.png";
import medicines from "../../../static/images/coverage_medicines.png";
import optical from "../../../static/images/coverage_optical.png";
import wellness from "../../../static/images/coverage_wellness.png";
import requiredReceipt from "../../../static/images/required_receipt--teal.png";
import requiredRx from "../../../static/images/required_rx--teal.png";
import requiredDocuments from "../../../static/images/required_documents--teal.png";
import requiredOnline from "../../../static/images/required_online--teal.png";
import processTriangleGrey from "../../../static/images/process_triangle--grey.png";
import receiveAmountOrange from "../../../static/images/flexmed/flexmed_receive-amount--orange.png";
import uploadClickOrange from "../../../static/images/flexmed/flexmed_upload-click--orange.png";
import receiveConfirmOrange from "../../../static/images/flexmed/flexmed_receive-confirm--orange.png";
import o_input from "../../../static/images/flexmed/order__input.png";
import o_medicineform from "../../../static/images/flexmed/order__medicine form.png";
import receivePayoutOrange from "../../../static/images/flexmed/flexmed_receive-payout--orange.png";
import o_physical from "../../../static/images/flexmed/order__physical.png";
import o_upload from "../../../static/images/flexmed/order__upload.png";
export const query = graphql`
  query {
    flexmedhero: file(relativePath: { eq: "flexmed/flexmed_hero_long.png" })
    {
      childImageSharp {
        fluid(
          maxWidth: 2000
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
   }`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">







    <Hero color="warning" size="medium" backgroundImage={props.data.flexmedhero.childImageSharp.fluid.src} mdxType="Hero">
      <Container isCentered mdxType="Container">
        <Container desktop={5} mdxType="Container">
          <div className="m-1 pt-2 pb-2">
            <h1>{`FlexMed`}</h1>
            <p>{`Take charge of your health and wellness goals. Reimburse medical benefits that matter.`}</p>
            <Button color="secondary" size="medium" className="mt-2" onClick={() => navigate("/flexmed/mechanics")} mdxType="Button">Upload Receipt</Button>
          </div>
        </Container>
      </Container>
    </Hero>
    <Hero mdxType="Hero">
      <Container isCentered desktop={9} fullhd={8} tablet={11} mdxType="Container">
        <h3 className="has-text-centered">How FlexMed works</h3>
        <div className="columns has-text-centered">
          <div className="column is-4 is-12-mobile">
            <div className="columns">
              <div className="column is-9-desktop is-12-mobile">
                <h4>Upload</h4>
              </div>
              <div className="column is-3 p-0 mt-3 has-text-right is-hidden-mobile"> 
                <img src={processTriangleGrey} alt="next" width="35%" />
              </div>
            </div>
            <div className="has-text-left">
              <Media image={uploadClickOrange} mdxType="Media"> Click <strong>Upload Receipts</strong> at <Link to="/" className="has-text-weight-bold has-text-secondary" mdxType="Link">maersk.medgrocer.com</Link></Media>
              <Media image={o_upload} mdxType="Media">Upload receipts and required documents</Media>
              <Media image={o_input} mdxType="Media">Input details</Media>
            </div>
          </div>
          <div className="column is-4">
            <div className="columns">
              <div className="column is-9">
                <h4>Submit</h4>
              </div>
              <div className="column is-3 p-0 mt-3 has-text-right  is-hidden-mobile"> 
                <img src={processTriangleGrey} alt="next" width="35%" />
              </div>
            </div>
            <div className="has-text-left">
              <Media image={o_physical} mdxType="Media">Submit required documents at the 7th floor payroll area</Media>
              <Media image={o_medicineform} mdxType="Media">Sign the receipt log</Media>
            </div>
          </div>
          <div className="column is-4">
            <div className="columns">
              <div className="column is-9">
                <h4>Received</h4>
              </div>
            </div>
            <div className="has-text-left">
              <Media image={receiveConfirmOrange} mdxType="Media">Receive email acknowledging online submission</Media>
              <Media image={receiveAmountOrange} mdxType="Media">Receive email with approved amount for reimbursement</Media>
              <Media image={receivePayoutOrange} mdxType="Media">Receive reimbursement in next payroll period</Media>
            </div>
          </div>
        </div>
      </Container>
    </Hero>
    <Hero isCentered color="warning" mdxType="Hero">
      <p>{`Want to maximize your Medical Allowance?`}</p>
      <h3 className="m-1">Check before you request</h3>
    </Hero>
    <Hero size="small" mdxType="Hero">
      <Container isCentered mdxType="Container">
        <h3 className="has-text-left">What's covered</h3>
        <div className="columns">
          <div className="column content p-2">
            <div className="has-text-centered">
              <img src={medicines} alt="medicines" width="80%" />
              <h4 className="has-text-primary"> Medical </h4>
            </div>
            <ul>
              <li parentName="ul">{`Prescription drugs and vaccines`}</li>
              <li parentName="ul">{`Over-the-counter drugs`}</li>
              <li parentName="ul">{`Laboratory tests and check-ups`}</li>
            </ul>
          </div>
          <div className="column content p-2">
            <div className="has-text-centered">
              <img src={optical} alt="optical" width="80%" />
              <h4 className="has-text-primary"> Optical</h4>
            </div>
            <ul>
              <li parentName="ul">{`Eyeglasses and contact lenses`}</li>
              <li parentName="ul">{`Saline solutions`}</li>
              <li parentName="ul">{`Optical check-ups`}</li>
            </ul>
          </div>
          <div className="column content p-2">
            <div className="has-text-centered">
              <img src={dental} alt="dental" width="80%" />
              <h4 className="has-text-primary"> Dental</h4>
            </div>
            <ul>
              <li parentName="ul">{`Braces and fillings`}</li>
              <li parentName="ul">{`Cleaning and sealants`}</li>
              <li parentName="ul">{`Dental check-ups`}</li>
            </ul>
          </div>
          <div className="column content p-2">
            <div className="has-text-centered">
              <img src={wellness} alt="wellness" width="80%" />
              <h4 className="has-text-primary"> Wellness</h4>
            </div>
            <ul>
              <li parentName="ul">{`Gym, yoga, boxing, etc.`}</li>
              <li parentName="ul"><span className="has-text-grey-light">Other wellness memberships</span></li>
              <li parentName="ul"><del className="has-text-grey-light">Medical and gym equipment</del></li>
            </ul>
          </div>
        </div>
        <h3 className="has-text-left mt-1">What's required</h3>
        <div className="columns">
          <div className="column content is-3">
  <div className="has-text-centered">
    <img src={requiredReceipt} alt="receipt requirements" width="60%" />
    <h4 className="has-text-primary"> Receipt </h4>
  </div>
            <ul>
              <li parentName="ul">{`Official Receipts or Sales Invoices with TIN`}</li>
              <li parentName="ul">{`Named to employee or immediate family`}</li>
              <li parentName="ul">{`Original, clear, and untampered`}</li>
            </ul>
          </div>
          <div className="column content is-3">
  <div className="has-text-centered">
    <img src={requiredRx} alt="prescription requirements" width="60%" />
    <h4 className="has-text-primary"> Prescription for: </h4>
  </div>
            <ul>
              <li parentName="ul">{`Prescription drugs, and vaccines`}</li>
              <li parentName="ul">{`Eyewear (e.g. reading glasses)`}</li>
              <li parentName="ul">{`Laboratory tests`}</li>
              <li parentName="ul">{`Antipsychotic/ antidepressant drugs`}</li>
              <li parentName="ul">{`Whey supplements`}</li>
            </ul>
          </div>
          <div className="column content is-3">
  <div className="has-text-centered">
    <img src={requiredDocuments} alt="required documents" width="60%" />
    <h4 className="has-text-primary"> Domestic/Common Law Spouse </h4>
  </div>
            <ul>
              <li parentName="ul">{`NSO birth certificate`}</li>
              <li parentName="ul">{`NSO marriage contract (if applicable)`}</li>
              <li parentName="ul">{`Barangay certification of co-habitation for more than 12 months`}</li>
              <li parentName="ul">{`NSO Certification of No Marriage (CENOMAR)`}</li>
            </ul>
          </div>
          <div className="column content is-3">
  <div className="has-text-centered">
    <img src={requiredOnline} alt="online purchase" width="60%" />
    <h4 className="has-text-primary"> Online purchase </h4>
  </div>
            <ul>
              <li parentName="ul">{`Transaction form`}</li>
              <li parentName="ul">{`Deposit slip or credit card billing statement`}</li>
            </ul>
          </div>
        </div>
      </Container>
    </Hero>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      